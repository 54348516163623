@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root {
    --header-height: 3rem;

    /* Colors */
    --hue: 0;
    --sat: 0%;
    --title-color: hsl(var(--hue), var(--sat), 20%);
    --title-color-dark: hsl(var(--hue), var(--sat), 0%);
    --text-color: hsl(var(--hue), var(--sat), 46%);
    --body-color: hsl(var(--hue), var(--sat), 98%);
    --container-color: #fff;
    --border-color: #ececec;
    --border-color-dark: #dcdcdc;
    --icon-hue: 50;
    --icon-sat: 5%;
    --icon-color: hsl(var(--icon-hue), var(--icon-sat), 57%);
    --icon-color-dark: hsl(var(--icon-hue), var(--icon-sat), 0%);

    --shadow: 0 1px 4px rgba(0, 0, 0, .15);

    /* Font and typography */
    --body-font: 'Poppins', sans-serif;

    --big-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
    --tiny-font-size: 0.625rem;

    /* Font weight */
    --font-normal: 400;
    --font-medium: 500;
    --font-semi-bold: 600;

    /* Margib bottom */
    --mb-0-25: 0.25rem;
    --mb-0-5: 0.5rem;
    --mb-0-75: 0.75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-25: 2.25rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;

    /* Z index */
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}

/* Responsive */
@media screen and (max-width: 992px) {
    :root {
        --big-font-size: 2.75rem;
        --h1-font-size: 1.5rem;
        --h2-font-size: 1.25rem;
        --h3-font-size: 1rem;
        --normal-font-size: 0.938rem;
        --small-font-size: 0.813rem;
        --smaller-font-size: 0.75rem;
    }
}

/* Base */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body,
button,
input,
textarea {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

body {
    background-color: var(--body-color);
    color: var(--text-color);
}

h1, h2, h3 {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

img {
    max-width: 100%;
    height: auto;
}

/* reusable classes */

.section {
    padding: 6rem 0 2rem;   
}

.section__title {
    font-size: var(--h1-font-size);
    color: var(--title-color);
}

.section__subtitle {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
    text-align: center;
}


/* Layout */
.container {
    max-width: 968px;
    margin-left: auto;
    margin-right: auto;
}

.grid {
    display: grid;
    gap: 1.5rem;
}


/* Buttons */

.button {
    display: inline-block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
}

.button:hover {
    background-color: var(--title-color-dark);
}

.button__icon {
    margin-left: var(--mb-0-5);
}

.button--flex {
    display: inline-flex;
    align-items: center;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.lds-ring,
.lds-ring div {
    box-sizing: border-box;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media screen and (max-width: 992px) {
    .container {
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
}


@media screen and (max-width: 768px) {
 
}


@media screen and (max-width: 576px) {
    
}

@media screen and (max-width: 350px) {
    .container {
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    }
}