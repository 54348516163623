.project__card__container {
    background-color: white;
    display: flex;
    width: 350px;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    border-radius: 20px;
    border: var(--border-color);
    box-shadow: var(--shadow);
    padding: 1rem;
}

.project__image {
    width: 300px;
    height: 300px;
    border-radius: 10px;
}
.project__information-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-left: 0.25rem;
}

.project__title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-semi-bold);
    line-break: auto;
}

.project__description {
    font-size: var(--normal-font-size);
    white-space: pre-line;
}
.project__links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    column-gap: 2rem;
}

.project__link-icon {
    font-size: 3rem;
    color: var(--icon-color);
}

.project__link-icon:hover {
    color: var(--icon-color-dark);
}


@media screen and (max-width: 992px) {
}


@media screen and (max-width: 768px) {

}


@media screen and (max-width: 576px) {
    .project__card__container {
        width: 300px;
    }
    .project__image {
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width: 350px) {
    .project__card__container {
        width: 80vw;
    }
    .project__image {
        width: 70vw;
        height: 70vw;
    }
}
