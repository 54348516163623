
.contact__container {
    margin-top: 6rem;
    justify-self: center;
    align-self: center;
}

.contact__form {
    margin: 4rem auto;
    width: 50vw;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    padding-bottom: 20px;
}
.form__control {
    padding: 5px;
    border-width: 0 0 1px 0;
    border-radius: 5px 5px 0 0;
    background-color: var(--container-color);
}

.submit__button {
    width: 40vw;
    justify-content: center;
    align-self: center;   
}
.error__message {
    color: red;
    font-size: 0.8rem;
    margin-top: 5px;
    justify-content: center;
    align-self: center;
}


@media screen and (max-width: 992px) {
    .submit__button {
        width: 50vw;
    }
}


@media screen and (max-width: 768px) {
    .contact__form {
        width: 70vw;
        row-gap: 1.5vh;
    }
    .submit__button {
        width: 70vw;
    }
}


@media screen and (max-width: 576px) {
    
    
}

@media screen and (max-width: 350px) {
    .contact__form {
        width: 80vw;
        row-gap: 1vh;
    }
}