.home__container {
    row-gap: 6rem;
}

.home__content {
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__social-icon:hover {
    color: var(--title-color-dark);
}

.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home__description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
}


a.linked-word {
    text-decoration: none;
    color: inherit;
    border-bottom: 1px dotted #000;
}

a.linked-word:hover {
    color: #555;
    border-bottom-color: #555;
}

.home__img {
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 40% 70%;
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}

.home__scroll {
    margin-left: 9.25rem;
}

.wheel {
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(3.75rem);
    }
}

.home__scroll-name {
    font-weight: var(--font-medium);
    color: var(--title-color);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home__scroll-arrow {
    font-size: 1.25rem;
    color: var(--title-color);
}

/** Breakpoints **/

@media screen and (max-width: 992px) {
    .home__content {
        grid-template-columns: 100px repeat(2, 1fr);
        column-gap: 1.25rem;
    }
    .home__subtitle {
        margin-bottom: var(--mb-1);
    }
    .home__subtitle::before {
        width: 42px;
        top: 0.8rem;
    }
    .home__description {
        max-width: initial;
        margin-bottom: var(--mb-2-25);
    }
    .home__img {
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
    }
    .home__scroll {
        margin-left: 7.5rem;
    }
}
@media screen and (max-width: 768px) {
    .home__content {
        display: grid;
        grid-template-columns: 0.1fr 10fr;
        grid-template-rows: auto auto;
        gap: 0.5rem;
        align-items: center;
        padding-top: 0.75rem;
    }

    .home__social {
        grid-column: 1;
        grid-row: 1;
        justify-self: start;
    }

    .home__img {
        grid-column: 2;
        grid-row: 1;
        justify-self: center;
        align-self: center;
        width: 200px;
        height: 200px;
    }

    .home__data {
        text-align: start;
        display: grid;
        grid-template-rows: auto;
        gap: 1rem;
        justify-self: initial;
    }

    .home__main__data {
        grid-column: 2;
        grid-row: 2;
    }

    .home__description {
        max-width: initial;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 350px) {
    .home__img {
        width: 180px;
        height: 180px;
    }
}