.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: center;
}

.about__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: center;
}

.about__preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2rem;
    margin-top: 2rem;
}

.about__preview-description {
    text-align: justify;
}

.about__preview-img {
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 0;
    justify-self: center;
    width: 300px;
    height: 300px;
    animation: profile__animate 8s ease-in-out infinite 1s;
}

.about__preview-content {
    width: 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 1.5rem;
}

.about__education {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.academic__links {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.about__education-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2rem;
    background-color: var(--body-color);
}

.education__content {
    width: 20rem;
    border: 0 solid var(--border-color);
    border-radius: 15px;
    box-shadow: var(--shadow);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.education__title {
    text-align: center;
}

.education__subtitle {
    text-align: center;
}

.education__description {
    text-align: justify;
    white-space: pre-line;
}

.experience-content {
    background-color: var(--body-color);
}

.about__experience-content {
    padding: 1.5rem;
    margin: 1.5rem;
    border-radius: 0.5rem;
    gap: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.about__experience-content:active {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.about__experience-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

.about__experience-subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    color: #666;
    margin-bottom: 0.5rem;
}

.about__experience-year {
    font-size: 1rem;
    color: #999;
    margin-bottom: 1rem;
}

.about__experience-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;
}

/* Skills section */
.about__experience-projects {
    display: flex;
    flex-wrap: wrap;
}

.about__experience-project {
    background-color: #f0f0f0;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    color: #333;
    transition: background-color 0.3s ease;
}

.about__experience-project:active {
    background-color: #e0e0e0;
}

.about__experience-tag {
    font-weight: 500;
}

/* Telefon için uyumluluk */

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 768px) {
    .about__experience-content {
        max-width: 500px;
        padding: 1rem;
    }

    .about__experience-title {
        font-size: 1.25rem;
    }

    .about__experience-subtitle {
        font-size: 1rem;
    }

    .about__experience-year {
        font-size: 0.9rem;
    }

    .about__experience-description {
        font-size: 0.9rem;
    }

    .about__experience-project {
        font-size: 0.8rem;
        padding: 0.4rem 0.8rem;
    }
}

@media screen and (max-width: 576px) {
    .about__experience-content {
        max-width: 350px;
    }
    .about__preview-subtitle {
        max-width: 350px;
    }
    .about__preview-description {
        max-width: 350px;
    }
}

@media screen and (max-width: 350px) {
    .about__experience-content {
        max-width: 200px;
    }
    .experience-content {
        margin: 8px;
    }
    .about__preview-subtitle {
        max-width: 200px;
    }
    .about__preview-description {
        max-width: 200px;
    }
}
