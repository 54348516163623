.projects {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.projects__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 6rem;
    gap: 1.5rem;
}

.projects__container-list {
    margin: 0 6rem 6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2rem;
}

.error__message {
    color: red;
    text-align: center;
    margin-top: 2rem;
}

a.linked-word {
    text-decoration: none;
    color: inherit;
    border-bottom: 1px dotted #000;
}

a.linked-word:hover {
    color: #555;
    border-bottom-color: #555;
}

@media screen and (max-width: 992px) {
    .projects__header, .projects__container-list {
        margin: 0 4rem;
    }
}

@media screen and (max-width: 768px) {
    .projects__header, .projects__container-list {
        margin: 0 2rem;
    }
}

@media screen and (max-width: 576px) {
    .projects__header, .projects__container-list {
        margin: 0 1rem;
    }
}

@media screen and (max-width: 350px) {
    .projects__header, .projects__container-list {
        margin: 0 0.5rem;
    }
}
